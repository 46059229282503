import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './index.css';


const rootElement = document.getElementById("root");

if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    console.error("Root container not found. Ensure there is a <div id='root'></div> in index.html.");
}
